import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
 import Voice from '../images/ei/enterprise-voice-enablement.jpeg'
import Integration from '../images/ei/enterprise-device-integration.jpeg'
import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const Ei = () => {
    return (
        <Layout pageTitle="PragICTS | Engineering Initiatives" og_desc="Ongoing engineering initiatives">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>Engineering <span><br></br> Initiatives</span></h2>
                                
                                <div className="horizonral-subtitle"><span>Engineering Initiatives</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="sec1">
                        <div className="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Engineering Initiatives</div>
                        <div className="container main-about">
                            <p>PragICTS is committed to ongoing strategic engineering initiatives aimed at evaluating the feasibility of new technologies for practical application throughout the enterprise. These initiatives serve as a catalyst for innovation, equipping us with the necessary skills and insights to leverage a diverse portfolio of technologies. This portfolio spans established, emerging, and disconnected technologies, ensuring that we remain at the forefront of pragmatic engineering. Here are some of the initiatives currently underway at PragICTS:
                            </p>

                        </div>
                        <div className="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div className="sec-lines"></div>
                    </section>
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={Voice}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                                <h3><strong>Voice Enablement of Key <br></br>Enterprise Software Functionality</strong></h3>
                                                <p><b>Status :In Progress</b><br></br>Voice enablement of enterprise functionality can provide instant access to application functionality on demand (any time-place-device).  For example, the HR manager can query Alexa on his phone to find out who is absent from work today or late from work. The managing director can query from his phone through Alexa the details pertaining to the sales on the previous date, etc. Possibilities are endless. Just talk and hear the response from Alexa on the above.
At present this is being tried through the AWS Alexa Platform with back-end integration with enterprise applications. We expect to get a demo platform in place for the marketing to take it to our prospective clients. Also once this is concluded on Alexa, we expect to expand across the other voice assistant platforms such as Apple Siri, Microsoft Cortana, Google Voice Assistant, etc.</p>
                                                <div className="clearfix"></div>
                                               
                                            </div>
                                        </a>
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={Integration}>
                                        <a target="_blank" href='#'>
                                            <div className="hidden-works-item-text">
                                            <h3><strong>Integration of Enterprise Assets <br></br>(Hardware + Software + Cloud)   For Real-time Tracking and Actioning for lnstant and Preventive Support.</strong></h3>
                                                <p><b>Status :In Progress</b><br></br>At present work is underway to integrate all IT devices of one our our key clients with multiple locations for proactive and preventive support and maintenance. At present this is being implemented using the Prometheus <a href='https://prometheus.io/'>(https://prometheus.io/)</a>for enterprise data aggregation and Grafana <a href='https://grafana.com/'>(https://grafana.com/)</a> for visualization and alerts. Current implementation is on an on-premise configuration and is expected for migration to a cloud centric (AWS) setup. Engineering personnel from the three verticals (software, cloud & infrastructure) of the company are brought together for this implementation. This also highlights the depth and breadth of engineering expertise of PragICTs under one roof.</p>
                                                <div className="clearfix"></div>
                                            </div>
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default Ei
